import React, { useEffect, useMemo, useRef } from 'react'
import * as classes from './Footer.module.scss'
import cn from 'classnames'
import Container from '../Container/Container'
import IconLogo from './IconLogo'
import { Link, navigate } from 'gatsby'
import Col from './Col'
import Social from '../Social/Social'
import Tablet from './Tablet/Tablet'
import Mobail from './Mobail/Mobail'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'
import { getHrefToHome } from '../../../utils/getHrefToHome'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import IconPartner from './../../icons/IconPartner'

gsap.registerPlugin(ScrollTrigger)

function Footer({ footer, projects, data, lang }) {
    const linksCompany = useMemo(() => footer.filter((item) => item?.parentSection?.id.includes(114159109)), [])
    const linksNews = useMemo(() => footer.filter((item) => item?.parentSection?.id.includes(114159272)), [])
    const linksContacts = useMemo(() => footer.filter((item) => item?.parentSection?.id.includes(114159305)), [])

    const policyLink = useMemo(() => deleteHtmlTag(data.policy.value, 'p'), [])
    const copy = useMemo(() => deleteHtmlTag(data.copy.value, 'p'), [])
    const developer = useMemo(() => deleteHtmlTag(data.developer.value, 'p'), [])

    const fb = data.fb.url
    const linkedin = data.linkedin.url
    const inst = data.inst.url
    const YouTube = data.YouTube.url

    const hrefHome = getHrefToHome(lang)
    const lineTopRef = useRef(null)

    const urlToNewLanguagePage = lang === 'hy' ? `/en/` : `/`
    const textButtonToggleLang = useMemo(() => deleteHtmlTag(data.language.value, 'p'), [])

    const linksProjects = useMemo(
        () =>
            projects.map((item) => {
                return { title: item.title, url: `${lang === 'en' ? '/en' : ''}/projects/${item.urlCode}/` }
            }),
        []
    )

    const company = {
        title: footer.find((item) => item.id.includes(114159109))?.title,
        items: linksCompany
    }

    const projectsContent = {
        title: footer.find((item) => item.id.includes(114159238))?.title,
        items: linksProjects
    }

    const pressCenter = {
        title: footer.find((item) => item.id.includes(114159272))?.title,
        items: linksNews
    }

    const contacts = {
        title: footer.find((item) => item.id.includes(114159305))?.title,
        items: linksContacts
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(lineTopRef.current, {
                duration: 1.4,
                width: '100%',
                ease: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                scrollTrigger: {
                    trigger: lineTopRef.current,
                    start: 'top 95%'
                }
            })
        }, 1500)

        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <footer className={cn(classes.Footer, 'js-block-dark')}>
                <Container>
                    <div className={cn(classes.Top, { [classes.LangHY]: lang === 'hy' })}>
                        <div className={classes.LineTop} ref={lineTopRef} />

                        <div className={classes.LogoWrap}>
                            <Link to={hrefHome}>
                                <IconLogo />
                            </Link>

                            <IconPartner />

                            <p className={classes.Copyright} dangerouslySetInnerHTML={{ __html: copy }} />
                        </div>

                        <Col data={company} className={classes.ColCompany} lang={lang} first>
                            <p className={classes.Policy} dangerouslySetInnerHTML={{ __html: policyLink }} />
                        </Col>

                        <Col data={projectsContent} className={classes.ColProjects} lang={lang}>
                            <div className={classes.SocialWrap}>
                                <Social fb={fb} linkedin={linkedin} inst={inst} YouTube={YouTube} />
                            </div>
                        </Col>

                        <Col data={pressCenter} className={classes.ColPressCenter} lang={lang} />

                        <Col data={contacts} className={classes.ColContacts} lang={lang}>
                            <p className={classes.Author} dangerouslySetInnerHTML={{ __html: developer }} />

                            <button className={classes.Lang} onClick={() => navigate(urlToNewLanguagePage)}>
                                {textButtonToggleLang}
                            </button>
                        </Col>
                    </div>
                </Container>
            </footer>

            <Tablet footer={footer} projects={projects} data={data} lang={lang} />
            <Mobail footer={footer} projects={projects} data={data} lang={lang} />
        </>
    )
}

export default Footer
