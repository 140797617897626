import { graphql, useStaticQuery } from 'gatsby'

export default function GetDataEn() {
    return useStaticQuery(graphql`
        {
            text: datoCmsRenshinPageOther(title: { eq: "On top" }, locale: { eq: "en" }) {
                value
            }
        }
    `)
}