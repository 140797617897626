import React from 'react'

function IconLenkidin() {
    return (
        <svg width={13} height={13} viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                opacity='0.5'
                d='M11.375 0.625H0.847656C0.382812 0.625 0 1.03516 0 1.52734V12C0 12.4922 0.382812 12.875 0.847656 12.875H11.375C11.8398 12.875 12.25 12.4922 12.25 12V1.52734C12.25 1.03516 11.8398 0.625 11.375 0.625ZM3.69141 11.125H1.88672V5.30078H3.69141V11.125ZM2.78906 4.48047C2.1875 4.48047 1.72266 4.01562 1.72266 3.44141C1.72266 2.86719 2.1875 2.375 2.78906 2.375C3.36328 2.375 3.82812 2.86719 3.82812 3.44141C3.82812 4.01562 3.36328 4.48047 2.78906 4.48047ZM10.5 11.125H8.66797V8.28125C8.66797 7.625 8.66797 6.75 7.73828 6.75C6.78125 6.75 6.64453 7.48828 6.64453 8.25391V11.125H4.83984V5.30078H6.5625V6.09375H6.58984C6.83594 5.62891 7.4375 5.13672 8.3125 5.13672C10.1445 5.13672 10.5 6.36719 10.5 7.92578V11.125Z'
                fill='white'
            />
        </svg>
    )
}

export default IconLenkidin
