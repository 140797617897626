// extracted by mini-css-extract-plugin
export var Wrapper = "Menu-module--Wrapper--hNkbE";
export var Inner = "Menu-module--Inner--zJmxT";
export var Body = "Menu-module--Body--T3hiS";
export var Header = "Menu-module--Header--S9K50";
export var Links = "Menu-module--Links--dtzQT";
export var LinkItem = "Menu-module--LinkItem--1+O8g";
export var NoActive = "Menu-module--NoActive--GSpG7";
export var Active = "Menu-module--Active--z6hWl";
export var LinkItemNum = "Menu-module--LinkItemNum--WfxhD";
export var InnerLinks = "Menu-module--InnerLinks--PojR5";
export var Open = "Menu-module--Open--4SybE";
export var Footer = "Menu-module--Footer--cymD3";
export var Policy = "Menu-module--Policy--QKpLg";
export var Author = "Menu-module--Author--C4OiZ";
export var Image = "Menu-module--Image--oOZ2p";
export var Social = "Menu-module--Social--i3esO";
export var LangHY = "Menu-module--LangHY--YUF6r";
export var arrowLeft = "Menu-module--arrowLeft--D5h9-";
export var arrowRight = "Menu-module--arrowRight--wPQb0";