import * as React from 'react'
import * as classes from './Footer.module.scss'
import { Link } from 'gatsby'
import cn from 'classnames'

function Col({ data, className, children, alignRight, lang, first }) {
    return (
        <div className={cn(classes.Col, className, { [classes.LangHY]: lang === 'hy' }, { [classes.First]: first })}>
            <div className={cn(classes.ColInner, { [classes.Right]: alignRight })}>
                <p className={classes.ColTitle}>{data.title}</p>

                <ul className={classes.ColItems}>
                    {data.items.map((item, index) => (
                        <li className={classes.ColItem} key={index}>
                            <Link to={item.url}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>

            {children}
        </div>
    )
}

export default Col
