import React from 'react'

function IconYouTube() {
    return (
        <svg width={16} height={11} viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                opacity='0.5'
                d='M15.1367 2.16797C14.9727 1.51172 14.4531 0.992188 13.8242 0.828125C12.6484 0.5 8 0.5 8 0.5C8 0.5 3.32422 0.5 2.14844 0.828125C1.51953 0.992188 1 1.51172 0.835938 2.16797C0.507812 3.31641 0.507812 5.77734 0.507812 5.77734C0.507812 5.77734 0.507812 8.21094 0.835938 9.38672C1 10.043 1.51953 10.5352 2.14844 10.6992C3.32422 11 8 11 8 11C8 11 12.6484 11 13.8242 10.6992C14.4531 10.5352 14.9727 10.043 15.1367 9.38672C15.4648 8.21094 15.4648 5.77734 15.4648 5.77734C15.4648 5.77734 15.4648 3.31641 15.1367 2.16797ZM6.46875 7.99219V3.5625L10.3516 5.77734L6.46875 7.99219Z'
                fill='white'
            />
        </svg>
    )
}

export default IconYouTube
