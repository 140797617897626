// extracted by mini-css-extract-plugin
export var Footer = "Footer-module--Footer--bfoBV";
export var Top = "Footer-module--Top--vNepd";
export var LangHY = "Footer-module--LangHY--WZ3yL";
export var Policy = "Footer-module--Policy--QpvPm";
export var Author = "Footer-module--Author--R6BTW";
export var LineTop = "Footer-module--LineTop--l+kgH";
export var LogoWrap = "Footer-module--LogoWrap--L-bjg";
export var Col = "Footer-module--Col--5VM5+";
export var ColContacts = "Footer-module--ColContacts--350ps";
export var First = "Footer-module--First--xbx-o";
export var ColTitle = "Footer-module--ColTitle---PCk6";
export var ColItem = "Footer-module--ColItem--kJ7d3";
export var ColCompany = "Footer-module--ColCompany--S6AnV";
export var ColItems = "Footer-module--ColItems--ulDPC";
export var Lang = "Footer-module--Lang--yKmBQ";
export var Bottom = "Footer-module--Bottom--U4cDA";
export var Copyright = "Footer-module--Copyright--zdkch";
export var SocialWrap = "Footer-module--SocialWrap--THwL1";
export var Inner = "Footer-module--Inner--Q6cgo";
export var ColInner = "Footer-module--ColInner--obFFl";
export var Right = "Footer-module--Right--IpLtZ";
export var ColProjects = "Footer-module--ColProjects--Q32At";
export var arrowLeft = "Footer-module--arrowLeft--HUtYd";
export var arrowRight = "Footer-module--arrowRight--iEdPD";