import * as React from 'react'
import * as classes from './Social.module.scss'
import cn from 'classnames'
import IconYouTube from './IconYouTube'
import IconFb from './IconFb'
import IconLenkidin from './IconLenkidin'
import IconInsta from './IconInsta'

function Social({ fb, linkedin, inst, YouTube, className }) {
    return (
        <div className={cn(classes.Social, className)}>
            {fb && (
                <a className={classes.Link} href={`${fb}`} target='_blank' rel='noopener noreferrer'>
                    <IconFb />
                </a>
            )}

            {linkedin && (
                <a className={classes.Link} href={`${linkedin}`} target='_blank' rel='noopener noreferrer'>
                    <IconLenkidin />
                </a>
            )}

            {inst && (
                <a className={classes.Link} href={`${inst}`} target='_blank' rel='noopener noreferrer'>
                    <IconInsta />
                </a>
            )}

            {YouTube && (
                <a className={classes.Link} href={`${YouTube}`} target='_blank' rel='noopener noreferrer'>
                    <IconYouTube />
                </a>
            )}
        </div>
    )
}

export default Social
