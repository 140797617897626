export const getHrefToHome = (language) => {
    switch (language) {
        case 'en':
            return '/en/'
        case 'hy':
            return '/'
        default:
            return
    }
}
