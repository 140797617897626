import React, { useEffect, useMemo, useRef } from 'react'
import * as classes from "./OnTop.module.scss"
import GetDataEn from './GetDataEn'
import GetDataHy from './GetDataHy'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'

export default function OnTop({lang}) {
    const element = useRef(null)

    const {text} = (lang === 'en' ? GetDataEn() : GetDataHy())
    const textPrint = useMemo(() => deleteHtmlTag((text.value ? text.value : ''), 'all'), [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const onWindowScroll = () => {
        const windowWidth = window.innerWidth

        if (windowWidth >= 768) {
            const windowHeight = window.innerHeight
            const blocksDark = document.querySelectorAll('.js-block-dark')
            const windowScroll = window.scrollY

            if (windowScroll >= (windowHeight * 2)) {
                element.current.setAttribute('data-active', 'Y')
            } else {
                element.current.removeAttribute('data-active')
            }

            if (blocksDark) {
                const elementProps = element.current.getBoundingClientRect()
                let theme = 'light'

                for (const block of blocksDark) {
                    const blockProps = block.getBoundingClientRect()
                    const blockHeight = blockProps.height
                    const blockTop = blockProps.top + windowScroll

                    const start = blockTop - windowHeight + (windowHeight - elementProps.top - (elementProps.height / 2))
                    const end = start + blockHeight

                    if (blockHeight && windowScroll >= start && windowScroll <= end) {
                        theme = 'dark'
                    }
                }

                if (theme === 'light') {
                    element.current.setAttribute('data-theme', 'reverse')
                } else {
                    element.current.removeAttribute('data-theme')
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll)

        return () => {
            window.removeEventListener('scroll', onWindowScroll)
        }
    }, [])

    return (
        <div
            className={classes.OnTop}
            onClick={scrollToTop}
            ref={element}
            data-theme="reverse"
        >
            <svg>
                <circle cx="40" cy="40" r="39" className={classes.Line} />
                <circle cx="40" cy="40" r="39" className={classes.LineHover} />
            </svg>

            {textPrint ? (
                <div
                    className={classes.Text}
                    dangerouslySetInnerHTML={{__html: textPrint}}
                />
            ) : null}
        </div>
    )
}