import { graphql, useStaticQuery } from 'gatsby'

export function GetDataHy() {
    return useStaticQuery(graphql`
        {
            text: datoCmsRenshinPageOther(title: { eq: "Cookie" }, locale: { eq: "hy" }) {
                value
            }
            
            buttonAccept: datoCmsRenshinPageOther(title: { eq: "Cookie: Button accept" }, locale: { eq: "hy" }) {
                value
            }
            
            buttonClose: datoCmsRenshinPageOther(title: { eq: "Cookie: Button close" }, locale: { eq: "hy" }) {
                value
            }
        }
    `)
}