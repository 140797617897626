import React, { useContext, Fragment } from 'react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'
import './../../../css/cookieConsent.css'
import './../../../css/normalize.css'
import './../../../css/global.css'
import './../../../css/fonts.css'
import Footer from '../../ui/Footer/Footer'
import * as classes from './Layout.module.scss'
import { FormContext } from '../../../context/index'
import { CSSTransition } from 'react-transition-group'
import Menu from '../../ui/Menu/Menu'
import { useDataFooterByHy } from '../../ui/Footer/useDataFooterByHy'
import { useDataFooterByEn } from '../../ui/Footer/useDataFooterByEn'
import Cookie from '../Cookie/Cookie'
import OnTop from '../OnTop/OnTop'

const Layout = ({ children, hiddenFooter, lang }) => {
    const { showModal, popupCookie } = useContext(FormContext)

    const footerByHY = useDataFooterByHy()
    const footerByEN = useDataFooterByEn()
    const footerAll = lang === 'hy' ? footerByHY : footerByEN
    const footer = lang === 'hy' ? footerByHY.menu.nodes : footerByEN.menu.nodes
    const projects = lang === 'hy' ? footerByHY.projects.nodes : footerByEN.projects.nodes

    return (
        <Fragment>
            <div className={classes.Layout}>
                <main>{children}</main>
                {!hiddenFooter && <Footer footer={footer} projects={projects} data={footerAll} lang={lang} />}
            </div>

            <OnTop lang={lang} />

            <CSSTransition in={showModal === 'menu'} timeout={200} mountOnEnter={true} unmountOnExit={true}>
                {(state) => <Menu className={state} lang={lang} />}
            </CSSTransition>

            <CSSTransition in={popupCookie} timeout={350} mountOnEnter={true} unmountOnExit={true}>
                {(state) => <Cookie className={state} lang={lang} />}
            </CSSTransition>
        </Fragment>
    )
}

export default Layout
