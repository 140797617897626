import { useStaticQuery, graphql } from 'gatsby'

export function useDataHeaderByHy() {
    const data = useStaticQuery(graphql`
        {
            menu: allDatoCmsRenshinMenu(sort: { fields: [sorting], order: ASC }, filter: { locale: { eq: "hy" } }) {
                nodes {
                    title
                    url
                    parentSection {
                        title
                    }
                }
            }

            policy: datoCmsRenshinPageOther(title: { eq: "Privacy Policy" }, locale: { eq: "hy" }) {
                value
            }

            developer: datoCmsRenshinPageOther(title: { eq: "Developer" }, locale: { eq: "hy" }) {
                value
            }

            language: datoCmsRenshinPageOther(title: { eq: "Language" }, locale: { eq: "en" }) {
                value
            }

            fb: datoCmsRenshinSocial(title: { eq: "Facebook" }, locale: { eq: "hy" }) {
                title
                url
            }

            linkedin: datoCmsRenshinSocial(title: { eq: "Linkedin" }, locale: { eq: "hy" }) {
                title
                url
            }

            inst: datoCmsRenshinSocial(title: { eq: "Instagram" }, locale: { eq: "hy" }) {
                title
                url
            }

            YouTube: datoCmsRenshinSocial(title: { eq: "Youtube" }, locale: { eq: "hy" }) {
                title
                url
            }
        }
    `)

    return data
}
