import React from 'react'

function IconInsta() {
    return (
        <svg width={13} height={13} viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                opacity='0.5'
                d='M6.5 3.60547C4.75 3.60547 3.35547 5.02734 3.35547 6.75C3.35547 8.5 4.75 9.89453 6.5 9.89453C8.22266 9.89453 9.64453 8.5 9.64453 6.75C9.64453 5.02734 8.22266 3.60547 6.5 3.60547ZM6.5 8.80078C5.37891 8.80078 4.44922 7.89844 4.44922 6.75C4.44922 5.62891 5.35156 4.72656 6.5 4.72656C7.62109 4.72656 8.52344 5.62891 8.52344 6.75C8.52344 7.89844 7.62109 8.80078 6.5 8.80078ZM10.4922 3.49609C10.4922 3.08594 10.1641 2.75781 9.75391 2.75781C9.34375 2.75781 9.01562 3.08594 9.01562 3.49609C9.01562 3.90625 9.34375 4.23438 9.75391 4.23438C10.1641 4.23438 10.4922 3.90625 10.4922 3.49609ZM12.5703 4.23438C12.5156 3.25 12.2969 2.375 11.5859 1.66406C10.875 0.953125 10 0.734375 9.01562 0.679688C8.00391 0.625 4.96875 0.625 3.95703 0.679688C2.97266 0.734375 2.125 0.953125 1.38672 1.66406C0.675781 2.375 0.457031 3.25 0.402344 4.23438C0.347656 5.24609 0.347656 8.28125 0.402344 9.29297C0.457031 10.2773 0.675781 11.125 1.38672 11.8633C2.125 12.5742 2.97266 12.793 3.95703 12.8477C4.96875 12.9023 8.00391 12.9023 9.01562 12.8477C10 12.793 10.875 12.5742 11.5859 11.8633C12.2969 11.125 12.5156 10.2773 12.5703 9.29297C12.625 8.28125 12.625 5.24609 12.5703 4.23438ZM11.2578 10.3594C11.0664 10.9062 10.6289 11.3164 10.1094 11.5352C9.28906 11.8633 7.375 11.7812 6.5 11.7812C5.59766 11.7812 3.68359 11.8633 2.89062 11.5352C2.34375 11.3164 1.93359 10.9062 1.71484 10.3594C1.38672 9.56641 1.46875 7.65234 1.46875 6.75C1.46875 5.875 1.38672 3.96094 1.71484 3.14062C1.93359 2.62109 2.34375 2.21094 2.89062 1.99219C3.68359 1.66406 5.59766 1.74609 6.5 1.74609C7.375 1.74609 9.28906 1.66406 10.1094 1.99219C10.6289 2.18359 11.0391 2.62109 11.2578 3.14062C11.5859 3.96094 11.5039 5.875 11.5039 6.75C11.5039 7.65234 11.5859 9.56641 11.2578 10.3594Z'
                fill='white'
            />
        </svg>
    )
}

export default IconInsta
