// extracted by mini-css-extract-plugin
export var FooterMobail = "Mobail-module--FooterMobail--FJ1zR";
export var Top = "Mobail-module--Top--MwVti";
export var LangHY = "Mobail-module--LangHY--P394v";
export var Policy = "Mobail-module--Policy--nXXfX";
export var Author = "Mobail-module--Author--Y-EQ5";
export var LineTop = "Mobail-module--LineTop--ApPKi";
export var LogoWrap = "Mobail-module--LogoWrap--U431Q";
export var Columns = "Mobail-module--Columns--f7S4V";
export var Col = "Mobail-module--Col--eN109";
export var InnerCol = "Mobail-module--InnerCol--K7V6n";
export var ColProjects = "Mobail-module--ColProjects--zmZFr";
export var ColPressCenter = "Mobail-module--ColPressCenter--+KGDl";
export var ColContacts = "Mobail-module--ColContacts--hKh5C";
export var ColTitle = "Mobail-module--ColTitle--NExF3";
export var ColItems = "Mobail-module--ColItems--hTyZB";
export var ColItem = "Mobail-module--ColItem--lsD9k";
export var Lang = "Mobail-module--Lang--5BDx5";
export var Bottom = "Mobail-module--Bottom--ocl9s";
export var Copyright = "Mobail-module--Copyright--IRL78";
export var SocialWrap = "Mobail-module--SocialWrap--C9mAh";
export var arrowLeft = "Mobail-module--arrowLeft--B7RpI";
export var arrowRight = "Mobail-module--arrowRight--PTwBS";