// extracted by mini-css-extract-plugin
export var FooterTablet = "Tablet-module--FooterTablet--Fbs2g";
export var Top = "Tablet-module--Top--tD91U";
export var LineTop = "Tablet-module--LineTop--Hck9U";
export var LogoWrap = "Tablet-module--LogoWrap--MiTiB";
export var Col = "Tablet-module--Col--FXuPo";
export var ColCompany = "Tablet-module--ColCompany--bqt6a";
export var ColPressCenter = "Tablet-module--ColPressCenter--+8OFW";
export var InnerCol = "Tablet-module--InnerCol--iw2gZ";
export var ColTitle = "Tablet-module--ColTitle--Iom5-";
export var ColItems = "Tablet-module--ColItems--Rr9wH";
export var ColItem = "Tablet-module--ColItem--itNbo";
export var Lang = "Tablet-module--Lang--UqUVg";
export var Bottom = "Tablet-module--Bottom--slQRj";
export var Copyright = "Tablet-module--Copyright--ObBTY";
export var Policy = "Tablet-module--Policy--enWns";
export var SocialWrap = "Tablet-module--SocialWrap--AZXX4";
export var Author = "Tablet-module--Author--h58Xr";
export var arrowLeft = "Tablet-module--arrowLeft--rXJem";
export var arrowRight = "Tablet-module--arrowRight--JYGcu";