import React from 'react'

function IconLogo() {
    return (
        <svg width={125} height={94} viewBox='0 0 125 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_375_14368)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M61.0223 67.7392C61.0223 63.789 63.7944 62.582 67.369 62.582H70.5788C74.1169 62.582 77.1808 63.7159 77.1808 67.7392V67.9953H74.7734V67.7392C74.7734 65.1789 72.2566 64.8132 70.1776 64.8132H67.369C65.144 64.8132 63.4297 65.1789 63.4297 67.7392C63.4297 70.263 66.0559 70.1532 67.9891 70.1532H70.5058C74.0439 70.1532 77.6914 70.8848 77.6914 75.3104C77.6914 79.4069 74.9558 80.4676 71.3083 80.4676H66.9678C63.3567 80.4676 60.6211 78.8583 60.6211 74.9081V74.6521H63.0285V74.9081C63.0285 77.7244 65.144 78.2365 67.5878 78.2365H71.0894C73.1685 78.2365 75.2111 77.7244 75.2111 75.2739C75.2111 72.6404 73.1321 72.4209 70.9435 72.4209H67.369C64.0498 72.3844 61.0223 71.726 61.0223 67.7392Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M21.8467 62.6172H21.0078V65.1043V70.0787V72.5658V77.9425V80.4662H23.4152H23.5975H37.6769V77.9425H23.5975V72.5658H37.0933V70.0787H23.5975V65.1043H37.6769V62.6172H21.8467Z' fill='white' fillOpacity='0.15' />
                <path fillRule='evenodd' clipRule='evenodd' d='M94.9833 80.4662H97.573V62.6172H94.9833V70.0055H82.91V62.6172H80.3203V80.4662H82.91V72.5293H94.9833V80.4662Z' fill='white' fillOpacity='0.15' />
                <path fillRule='evenodd' clipRule='evenodd' d='M55.5888 72.4948V62.5827H58.1785V74.8356L55.5888 72.4948ZM40.9258 61.9609L53.838 73.7384L55.1875 74.9819L55.5888 75.3477L58.1785 77.7251V81.1267L43.5155 67.7765V80.5049H40.9258V61.9609Z' fill='white' fillOpacity='0.15' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.6262 64.8838V70.2239H11.3073C12.2191 70.2239 13.3134 70.1142 14.0429 69.4924C15.283 68.4317 15.4289 66.676 14.0794 65.6153C13.3499 65.0301 12.3286 64.8473 11.4167 64.8473H2.6262V64.8838ZM2.6262 72.5648V80.4651H0V62.543H10.7237C12.4745 62.543 14.5535 62.8356 15.9396 64.006C17.7633 65.5422 18.0551 68.1757 16.6326 70.0044C16.2679 70.4434 15.7937 70.8457 15.283 71.1383C17.0338 71.9795 17.2527 74.4301 17.2527 76.1126V80.4651H14.7724V75.9297C14.7724 75.2714 14.663 73.6255 14.1523 73.15C13.6417 72.6745 12.7663 72.5648 12.1097 72.5648H2.6262Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path fillRule='evenodd' clipRule='evenodd' d='M87.2489 16.6797H78.349L58.4336 28.2011V33.3217L87.2489 16.6797Z' fill='white' fillOpacity='0.2' />
                <path fillRule='evenodd' clipRule='evenodd' d='M87.2489 27.9431L82.7989 25.3828L58.4336 39.4645V44.6217L87.2489 27.9431Z' fill='white' fillOpacity='0.2' />
                <path fillRule='evenodd' clipRule='evenodd' d='M87.2489 39.2453L82.7989 36.6484L58.4336 50.7667V55.8874L87.2489 39.2453Z' fill='white' fillOpacity='0.2' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4332 28.1991L53.582 25.3827L58.4332 22.5664V28.1991Z' fill='white' fillOpacity='0.2' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4338 16.4228V11.2656L43.8438 19.7512L48.2572 22.3115L58.4338 16.4228Z' fill='white' fillOpacity='0.2' />
                <path fillRule='evenodd' clipRule='evenodd' d='M29.6562 16.6797H38.5197L58.4351 28.2011V33.3217L29.6562 16.6797Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M29.6562 27.9431L34.1062 25.3828L58.4351 39.4645V44.6217L29.6562 27.9431Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M29.6562 39.2453L34.1062 36.6484L58.4351 50.7667V55.8874L29.6562 39.2453Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4336 28.1991L63.3213 25.3827L58.4336 22.5664V28.1991Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4336 16.4228V11.2656L73.0601 19.7512L68.6101 22.3115L58.4336 16.4228Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4336 0L87.2489 16.6786H78.349L58.4336 5.12062V0Z' fill='white' fillOpacity='0.05' />
                <path fillRule='evenodd' clipRule='evenodd' d='M58.4312 0L29.6523 16.6786H38.5158L58.4312 5.12062V0Z' fill='white' fillOpacity='0.2' />
                <path d='M103.918 62.6172H101.328V80.4662H103.918V62.6172Z' fill='white' fillOpacity='0.15' />
                <path fillRule='evenodd' clipRule='evenodd' d='M122.304 72.4948V62.5827H124.893V74.8356L122.304 72.4948ZM107.641 61.9609L120.553 73.7384L121.902 74.9819L122.304 75.3477L124.893 77.7251V81.1267L110.23 67.7765V80.5049H107.641V61.9609Z' fill='white' fillOpacity='0.15' />
                <path
                    d='M4.62959 86.9766H5.43204V93.8162H4.62959V92.6824H4.55664C4.22837 93.3773 3.53534 93.8894 2.51404 93.8894C1.20094 93.8894 0.289062 93.0116 0.289062 91.4022V86.9766H1.09151V91.3657C1.09151 92.4264 1.78454 93.1579 2.76937 93.1579C3.68124 93.1579 4.62959 92.4629 4.62959 91.2194V86.9766Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M8.53648 86.9769H9.30245V88.0376H9.3754C9.6672 87.3427 10.3967 86.8672 11.2356 86.8672C11.3815 86.8672 11.5639 86.8672 11.6369 86.8672V87.6719C11.5639 87.6719 11.3815 87.6353 11.1627 87.6353C10.1049 87.6353 9.30245 88.3668 9.30245 89.3909V93.8166H8.5V86.9769H8.53648Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M14.1523 84.7109H14.9548V88.2954H15.0277C15.2831 87.6736 15.8667 86.9055 17.1068 86.9055C18.7847 86.9055 19.9154 88.3319 19.9154 90.4533C19.9154 92.5747 18.7847 94.0012 17.1068 94.0012C15.8667 94.0012 15.2831 93.2331 15.0277 92.5747H14.9183V93.8549H14.1523V84.7109ZM17.0339 93.2331C18.3835 93.2331 19.1494 91.9895 19.1494 90.4168C19.1494 88.844 18.4199 87.637 17.0339 87.637C15.6843 87.637 14.9548 88.8074 14.9548 90.4168C14.9548 92.0627 15.7208 93.2331 17.0339 93.2331Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M22.3594 91.9157C22.3594 90.5624 23.4536 90.1235 24.9856 89.9406C26.1163 89.7943 26.7729 89.7943 26.7729 89.2823V89.136C26.7729 88.185 26.1893 87.6364 25.168 87.6364C24.1831 87.6364 23.5631 88.1484 23.3077 88.7336L22.5418 88.4776C23.0159 87.3437 24.0737 86.9414 25.1315 86.9414C26.1893 86.9414 27.5388 87.3803 27.5388 89.2457V93.8908H26.7364V92.7935H26.6999C26.4081 93.4153 25.7151 94.0371 24.5844 94.0371C23.3442 94.0005 22.3594 93.2325 22.3594 91.9157ZM26.7364 91.2208V90.2332C26.5175 90.4527 25.4962 90.599 24.9491 90.6356C23.7819 90.7819 23.1254 91.1111 23.1254 91.9523C23.1254 92.7935 23.7819 93.269 24.6938 93.269C25.9704 93.269 26.7364 92.3181 26.7364 91.2208Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M31.4431 93.8166H30.6406V86.9769H31.4066V88.0376H31.4796C31.8078 87.3427 32.5009 86.8672 33.4857 86.8672C34.8717 86.8672 35.7836 87.745 35.7836 89.3543V93.78H35.0176V89.3909C35.0176 88.2571 34.3246 87.5621 33.3033 87.5621C32.2455 87.5621 31.4431 88.2936 31.4431 89.5006V93.8166Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M45.7734 85.2571C45.7734 84.9279 46.0288 84.6719 46.357 84.6719C46.6853 84.6719 46.9406 84.9279 46.9406 85.2571C46.9406 85.5863 46.6853 85.8423 46.357 85.8423C46.0288 85.8057 45.7734 85.5497 45.7734 85.2571ZM45.9558 86.9762H46.7583V93.8158H45.9558V86.9762Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M50.6618 93.8166H49.8594V86.9769H50.6254V88.0376H50.6983C51.0266 87.3427 51.7196 86.8672 52.7044 86.8672C54.0905 86.8672 55.0024 87.745 55.0024 89.3543V93.78H54.1999V89.3909C54.1999 88.2571 53.5069 87.5621 52.4856 87.5621C51.4278 87.5621 50.6254 88.2936 50.6254 89.5006V93.8166H50.6618Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path d='M60.6552 93.8163H59.8162L57.3359 86.9766H58.2113L60.2539 92.7556H60.3269L62.3695 86.9766H63.2084L60.6552 93.8163Z' fill='white' fillOpacity='0.15' />
                <path
                    d='M65.1094 90.4502C65.1094 88.3654 66.313 86.9023 68.1003 86.9023C69.5958 86.9023 70.9818 87.963 70.9818 90.2673V90.6697H65.9118C65.9483 92.2424 66.8237 93.2665 68.2097 93.2665C69.1946 93.2665 69.7782 92.7545 70.07 92.1327L70.8359 92.3887C70.5077 93.23 69.5958 93.9981 68.2462 93.9981C66.3131 93.9615 65.1094 92.535 65.1094 90.4502ZM70.1794 89.9381C70.1794 88.6214 69.304 87.5973 68.1003 87.5973C66.8602 87.5973 65.9848 88.658 65.9118 89.9381H70.1794Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M77.58 88.6946C77.3612 88.0728 76.9235 87.5973 76.0116 87.5973C75.0997 87.5973 74.4432 88.0728 74.4432 88.7677C74.4432 89.3529 74.8444 89.7187 75.6833 89.9016L76.7046 90.1576C77.8354 90.4502 78.419 91.0354 78.419 91.9498C78.419 93.1202 77.3977 93.9249 75.9022 93.9249C74.5526 93.9249 73.6772 93.3031 73.4219 92.1693L74.1879 91.9864C74.3702 92.7911 74.9538 93.23 75.9022 93.23C76.96 93.23 77.6165 92.7179 77.6165 91.9864C77.6165 91.4377 77.2518 91.0354 76.5223 90.8891L75.3915 90.6331C74.1879 90.3405 73.6407 89.7187 73.6407 88.8043C73.6407 87.6704 74.6256 86.9023 76.0116 86.9023C77.2518 86.9023 77.9813 87.5241 78.3095 88.5117L77.58 88.6946Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M83.8557 87.6743H82.3967V92.0268C82.3967 92.9047 82.9073 93.1241 83.418 93.1241C83.6368 93.1241 83.7827 93.0875 83.8921 93.051L84.0745 93.7825C83.9286 93.8556 83.7098 93.8922 83.3815 93.8922C82.5061 93.8922 81.6307 93.307 81.6307 92.1731V87.6377H80.6094V86.9428H81.6307V85.2969H82.4331V86.9428H83.8921V87.6743H83.8557Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M86.6641 86.9755H87.43V88.0362H87.503C87.7583 87.3413 88.4513 86.9023 89.3267 86.9023C90.2751 86.9023 90.8587 87.3778 91.187 88.1459H91.2599C91.5517 87.3778 92.2812 86.9023 93.2661 86.9023C94.5427 86.9023 95.3087 87.707 95.3087 89.2432V93.8152H94.5062V89.2432C94.5062 88.1825 93.9226 87.6339 93.0107 87.6339C91.9894 87.6339 91.3693 88.3654 91.3693 89.3529V93.8518H90.5669V89.2066C90.5669 88.2922 90.0198 87.6704 89.1079 87.6704C88.1595 87.6704 87.4665 88.4385 87.4665 89.4992V93.8883H86.6641V86.9755Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M98.0469 90.4502C98.0469 88.3654 99.2506 86.9023 101.038 86.9023C102.533 86.9023 103.919 87.963 103.919 90.2673V90.6697H98.8493C98.8858 92.2424 99.7612 93.2665 101.147 93.2665C102.132 93.2665 102.716 92.7545 103.007 92.1327L103.773 92.3887C103.445 93.23 102.533 93.9981 101.184 93.9981C99.2506 93.9615 98.0469 92.535 98.0469 90.4502ZM103.117 89.9381C103.117 88.6214 102.242 87.5973 101.038 87.5973C99.7977 87.5973 98.9223 88.658 98.8493 89.9381H103.117Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M107.455 93.8166H106.652V86.9769H107.418V88.0376H107.491C107.82 87.3427 108.513 86.8672 109.497 86.8672C110.883 86.8672 111.795 87.745 111.795 89.3543V93.78H110.993V89.3909C110.993 88.2571 110.3 87.5621 109.279 87.5621C108.221 87.5621 107.418 88.2936 107.418 89.5006V93.8166H107.455Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M117.633 87.6743H116.174V92.0268C116.174 92.9047 116.685 93.1241 117.195 93.1241C117.414 93.1241 117.56 93.0875 117.669 93.051L117.852 93.7825C117.706 93.8556 117.487 93.8922 117.159 93.8922C116.283 93.8922 115.408 93.307 115.408 92.1731V87.6377H114.387V86.9428H115.408V85.2969H116.21V86.9428H117.669V87.6743H117.633Z'
                    fill='white'
                    fillOpacity='0.15'
                />
                <path
                    d='M124.158 88.6946C123.939 88.0728 123.502 87.5973 122.59 87.5973C121.678 87.5973 121.021 88.0728 121.021 88.7677C121.021 89.3529 121.423 89.7187 122.261 89.9016L123.283 90.1576C124.413 90.4502 124.997 91.0354 124.997 91.9498C124.997 93.1202 123.976 93.9249 122.48 93.9249C121.131 93.9249 120.255 93.3031 120 92.1693L120.766 91.9864C120.948 92.7911 121.532 93.23 122.48 93.23C123.538 93.23 124.195 92.7179 124.195 91.9864C124.195 91.4377 123.83 91.0354 123.1 90.8891L121.97 90.6331C120.766 90.3405 120.219 89.7187 120.219 88.8043C120.219 87.6704 121.204 86.9023 122.59 86.9023C123.83 86.9023 124.559 87.5241 124.888 88.5117L124.158 88.6946Z'
                    fill='white'
                    fillOpacity='0.15'
                />
            </g>
            <defs>
                <clipPath id='clip0_375_14368'>
                    <rect width={125} height={94} fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconLogo
