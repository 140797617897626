import React, { useContext, useEffect, useMemo, useRef } from 'react'
import * as classes from "./Cookie.module.scss"
import { FormContext } from '../../../context'
import Cookies from 'js-cookie'
import { GetDataEn } from './GetDataEn'
import { GetDataHy } from './GetDataHy'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'

export default function Cookie({lang}) {
    const { setPopupCookie } = useContext(FormContext)
    const block = useRef(null)
    const {text, buttonAccept, buttonClose} = (lang === 'en' ? GetDataEn() : GetDataHy())

    const textPrint = useMemo(() => deleteHtmlTag((text.value ? text.value : ''), 'all'), [])
    const buttonAcceptPrint = useMemo(() => deleteHtmlTag((buttonAccept.value ? buttonAccept.value : ''), 'all'), [])
    const buttonClosePrint = useMemo(() => deleteHtmlTag((buttonClose.value ? buttonClose.value : ''), 'all'), [])

    const close = () => {
        Cookies.set('cookie-close', 'Y', {
            expires: 365,
            path: '/'
        })

        block.current.removeAttribute('data-active')
        setPopupCookie(false)
    }

    useEffect(() => {
        block.current.setAttribute('data-active', 'Y')
    }, [])

    return (
        <div
            className={classes.Cookie}
            ref={block}
        >
            {textPrint ? (
                <div
                    className={classes.Text}
                    dangerouslySetInnerHTML={{__html: textPrint}}
                />
            ) : null}

            {buttonAcceptPrint || buttonClosePrint ? (
                <div className={classes.Buttons}>
                    {buttonAcceptPrint ? (
                        <div
                            className={classes.Accept}
                            onClick={close}
                            dangerouslySetInnerHTML={{__html: buttonAcceptPrint}}
                        />
                    ) : null}

                    {buttonClosePrint ? (
                        <div
                            className={classes.Close}
                            onClick={close}
                            dangerouslySetInnerHTML={{__html: buttonClosePrint}}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}