export function deleteHtmlTag(str, tag) {
    let string = (str ? str : '')

    if (string) {
        if (tag === 'p') {
            return string.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')
        } else if (tag === 'all') {
            let stringNew = string

            stringNew = stringNew.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')
            stringNew = stringNew.replace(/(<span[^>]+?>|<span>|<\/span>)/gim, '')
            stringNew = stringNew.replace(/(<div[^>]+?>|<div>|<\/div>)/gim, '')
            stringNew = stringNew.replace(/(<strong[^>]+?>|<strong>|<\/strong>)/gim, '')
            stringNew = stringNew.replace(/(<b[^>]+?>|<b>|<\/b>)/gim, '')

            return stringNew
        }
    }

    return string
}
