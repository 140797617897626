import React, { useContext, useEffect, useState, useMemo } from 'react'
import * as classes from './Menu.module.scss'
import cn from 'classnames'
import Social from '../Social/Social'
import { FormContext } from '../../../context/index'
import { Link } from 'gatsby'
import photo from './bg_menu.svg'
import { useDataHeaderByHy } from '../Header/useDataHeaderByHy'
import { useDataHeaderByEn } from './../Header/useDataHeaderByEn'
import { deleteHtmlTag } from './../../../utils/deleteHtmlTag'
import anime from 'animejs'

function Menu({ lang }) {
    const menyByHY = useDataHeaderByHy()
    const menyByEN = useDataHeaderByEn()
    const menuData = lang === 'hy' ? menyByHY : menyByEN
    const menuLinks = menuData.menu.nodes

    const policyLink = useMemo(() => deleteHtmlTag(menuData.policy.value, 'p'), [])
    const developer = useMemo(() => deleteHtmlTag(menuData.developer.value, 'p'), [])

    const menu = useMemo(
        () =>
            menuLinks.map((item) => {
                const innerLinks = menuLinks.filter((itemParent) => itemParent?.parentSection?.title === item.title)

                return { ...item, innerLinks }
            }),
        []
    )

    const menuResult = useMemo(() => menu.filter(({ parentSection }) => !parentSection), [])

    const { onCloseModal } = useContext(FormContext)
    const [isHomePage, setIsHomePage] = useState(true)
    const [activeIndex, setActiveIndex] = useState(null)

    const onChangeActiveIndex = (index) => {
        setActiveIndex(index)
    }

    const handlerOnClick = (e, item, index) => {
        onChangeActiveIndex(index)

        const currentInnerLinksBlock = document.querySelector(`[data-id="${index}"]`)

        anime({
            targets: currentInnerLinksBlock,
            duration: 400,
            easing: 'linear',
            opacity: [0, 1],
            top: ['-100%', 0]
        })

        const isInnerLinks = item.innerLinks.length

        if (isInnerLinks) {
            return e.preventDefault()
        }

        onCloseModal()
    }

    useEffect(() => {
        console.log('window.location', window.location)

        const isHome = window.location.pathname === '/' || window.location.pathname === '/en/'
        setIsHomePage(isHome)
    }, [])

    return (
        <div className={cn(classes.Wrapper, { [classes.LangHY]: lang === 'hy' })}>
            <div className={classes.Inner}>
                <div className={classes.Header} />

                <div className={classes.Body}>
                    <ul className={classes.Links}>
                        {menuResult.map((item, mainIndex) => (
                            <li className={cn(classes.LinkItem, { [classes.NoActive]: !isHomePage })} key={mainIndex}>
                                <span className={classes.LinkItemNum}>0{mainIndex + 1}</span>

                                <Link
                                    activeClassName={classes.Active}
                                    to={`${item.url}`}
                                    onClick={(e) => {
                                        handlerOnClick(e, item, mainIndex)
                                    }}
                                    partiallyActive={true}
                                >
                                    {item.title}
                                </Link>

                                {item.innerLinks.length ? (
                                    <div
                                        className={cn(classes.InnerLinks, `innerLinks`, { [classes.Open]: activeIndex === mainIndex })}
                                        data-id={`${mainIndex}`}
                                    >
                                        {item.innerLinks.map((item, index) => (
                                            <Link
                                                key={index}
                                                activeClassName={classes.Active}
                                                to={`${item.url}`}
                                                onClick={() => {
                                                    onCloseModal()
                                                }}
                                                partiallyActive={true}
                                            >
                                                {item.title}
                                            </Link>
                                        ))}
                                    </div>
                                ) : null}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={classes.Footer}>
                    <p className={classes.Policy} dangerouslySetInnerHTML={{ __html: policyLink }} />

                    <Social
                        className={classes.Social}
                        fb={menuData?.fb.url}
                        linkedin={menuData?.linkedin.url}
                        inst={menuData?.inst.url}
                        YouTube={menuData?.YouTube.url}
                        black
                    />

                    <p className={classes.Author} dangerouslySetInnerHTML={{ __html: developer }} />
                </div>

                <div className={classes.Image} style={{ backgroundImage: `url(${photo})` }} />
            </div>
        </div>
    )
}

export default Menu
